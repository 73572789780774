<template>
  <div :key="key" class="word-cloud">
    <div class="poll-title">
      <span v-line-clamp="4" class="poll-title__text">{{ poll.title }}</span>
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>
    <div v-if="poll.test_questions_prompt" class="text_prompt">
      {{ poll.test_questions_prompt }}
    </div>
    <template v-for="(value, key) in inputNumArray">
      <word-cloud-input
        :key="key"
        v-model="answers[key]"
        :max-answer-length="poll.props.max_answer_length_value"
      />
    </template>
  </div>
</template>

<script>
import WordCloudInput from "./WordCloudInput";
import BasePoll from "./BasePoll";

export default {
  name: "WordCloudPoll",
  components: {
    WordCloudInput,
  },
  extends: BasePoll,
  model: {
    event: "model-input",
  },
  data() {
    return {
      answers: [],
      // Ключ вопроса. Необходим для перерендера.
      key: 0,
    };
  },
  computed: {
    inputNumArray() {
      if (!this.poll) {
        return [];
      }
      if (typeof this.poll.props === "string") {
        this.poll.props = JSON.parse(this.poll.props);
      }
      return new Array(this.poll.props.input_num);
    },
    hasError() {
      return this.answers.length < this.poll.props.input_num;
    },
    errorMessage() {
      return this.$emit("error", this.$t("testNGames.allFieldsAreRequired"));
    },
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },
  watch: {
    // Наблюдение за изменением вопроса.
    // При изменении вопроса - необходимо перерендерить элементы.
    poll: {
      handler() {
        this.key += 1;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.poll-title {
  position: relative;
  padding-bottom: 25px;
  margin: 15px 15px 25px 15px;
  font-size: 24px;
  //color: #232323;
  text-align: center;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px 7.5px;
  }

  &__text {
    /* stylelint-disable */
    word-break: break-word !important;
  }
}
.text_prompt {
  position: relative;
  padding-bottom: 5px;
  margin: 15px 15px 25px;
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: break-spaces;
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}
</style>
