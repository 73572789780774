<template>
  <div class="event-recording">
    <loader v-show="!onAir" class="event-recording__loader" />
    <div v-show="onAir" class="event-recording__container">
      <div ref="localStream" class="event-recording__local-stream" style="display: none"></div>
      <speaker-recording-presenter
        :presentation-info="presentationInfo"
        class="event-recording__presenter"
      />
      <speaker-recording-panel
        :speaker-name="speakerName"
        :date="date"
        :only-player="isStreamRecording"
      >
        <div v-if="onAir" class="event-recording__player-wrapper">
          <video-player
            v-if="isTypicalType"
            class="event-recording__player"
            :provided-url="rtmpUrl"
            autoplay
            :multilingual="multilingual"
          />
          <broadcast-web-rtc-player-one2-many
            v-if="isOne2ManyType"
            class="event-recording__player"
            :room="room"
            :speaker-avatar="speakerState.avatar"
            :speaker-video-muted="speakerState.videoMuted"
            :stream-name="streamName"
            :stream-capture-name="streamCaptureName"
            :url-server="streamServer"
            :type="viewerType"
            :resolution="speakerState.resolution"
            autoplay
            prevent-disconnect
          />
          <broadcast-web-rtc-player-conference
            v-if="isConferenceType"
            :room="room"
            :type="viewerType"
            :stream-name="streamName"
            :stream-capture-name="streamCaptureName"
            :url-server="streamServer"
            :user-id="user.id"
            :user-name="user.fio"
            :only-full-screen="isStreamRecording"
            :disabled-controls="isStreamRecording || isEventRecording"
            autoplay
          />
        </div>
      </speaker-recording-panel>
    </div>
    <system-notifications group="recording" position="top right" />
    <event-web-socket-container />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { auth, broadcast, presentation, room } from "@/store/modules/store.namespaces";
import {
  IS_CONFERENCE_TYPE,
  IS_ONE_2_MANY_TYPE,
  IS_TYPICAL_TYPE,
} from "@/store/modules/broadcast/getter-types";
import {
  NGENIX_URL_POSTPART,
  NGENIX_URL_PREPART,
  RECORD_MESSAGES,
  RecordTypes,
  STREAM_TYPES,
} from "@/constants/broadcast/broadcast-const";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDE,
} from "@/store/modules/presentation/getter-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";

import flashphonerMixin from "@/components/common/broadcast/mixins/flashphonerMixin";
import SystemNotifications from "@/components/common/notifications/SystemNotifications";
import EventWebSocketContainer from "@/components/event/EventWebSocketContainer";
import VideoPlayer from "@/components/event/room/VideoPlayer";
import BroadcastWebRtcPlayerOne2Many from "@/components/common/broadcast/BroadcastWebRtcPlayerOne2Many";
import BroadcastWebRtcPlayerConference from "@/components/common/broadcast/BroadcastWebRtcPlayerConference";
import SpeakerRecordingPresenter from "@/components/speaker/recording/SpeakerRecordingPresenter";
import SpeakerRecordingPanel from "@/components/speaker/recording/SpeakerRecordingPanel";
import Loader from "@/components/common/elements/Loader";
import config from "@/settings/config";

const CLOSE_WINDOW_TIMEOUT = 2000;
const DATE_UPDATE = 1000;

export default {
  name: "ViewEventRecording",
  components: {
    Loader,
    SystemNotifications,
    EventWebSocketContainer,
    VideoPlayer,
    BroadcastWebRtcPlayerOne2Many,
    BroadcastWebRtcPlayerConference,
    SpeakerRecordingPresenter,
    SpeakerRecordingPanel,
  },
  mixins: [flashphonerMixin],
  data() {
    return {
      recordingChannel: null,
      date: "",
      dateTimer: null,
      recordTypeId: +this.$route.query.typeId,
      room: this.$route.params.id,
    };
  },
  computed: {
    ...mapState(broadcast, [
      "streamName",
      "streamCaptureName",
      "streamServer",
      "speakerState",
      "onAir",
    ]),
    ...mapState(auth, ["user"]),
    ...mapGetters(broadcast, {
      isConferenceType: IS_CONFERENCE_TYPE,
      isTypicalType: IS_TYPICAL_TYPE,
      isOne2ManyType: IS_ONE_2_MANY_TYPE,
    }),
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
      currentPresentationSlide: CURRENT_PRESENTATION_SLIDE,
    }),
    ...mapGetters(room, {
      roomModules: AVAILABLE_ROOM_MODULES,
    }),
    speakerName() {
      return this.currentPresentation?.leader?.fio;
    },
    presentationInfo() {
      return {
        name: this.currentPresentation?.name,
        slidesLength: this.currentPresentation?.slidesArray?.length,
        currentSlide: this.currentPresentationSlide?.ord,
      };
    },
    isStreamRecording() {
      return this.recordTypeId === RecordTypes.Stream;
    },
    isEventRecording() {
      return this.recordTypeId === RecordTypes.Event;
    },
    documentTitle() {
      return `${
        this.isStreamRecording
          ? this.$t("broadcast.recordTypes.stream")
          : this.$t("broadcast.recordTypes.event")
      } ${this.room}`;
    },
    rtmpUrl() {
      if (this.streamName) {
        if (config.flashphonerHlsUrl) {
          return `${config.flashphonerHlsUrl}/${this.streamName}/${this.streamName}.m3u8`;
        }

        return `${NGENIX_URL_PREPART}rtmp_${this.streamName}${NGENIX_URL_POSTPART}`;
      }
      return "";
    },
    viewerType() {
      return STREAM_TYPES.VIEWER;
    },
    multilingual() {
      return this.roomModules.streamMultilingual && !this.rtmpUrl && this.isTypicalType;
    },
  },
  watch: {
    async onAir(value) {
      if (!value) {
        setTimeout(window.close, CLOSE_WINDOW_TIMEOUT);
      }
    },
  },
  created() {
    document.title = this.documentTitle;
    this.createTimer();
    this.createRecordingChannel();
  },
  beforeDestroy() {
    if (this.dateTimer) {
      clearInterval(this.dateTimer);
    }
    this.closeRecordingChannel();
  },
  beforeRouteLeave(to, from, next) {
    // прерываем редирект чтобы избежать перехода по роуту при запуске интерактивов
    next(false);
  },
  methods: {
    createTimer() {
      const format = "DD MMMM GGGG, HH:MM Z";
      this.date = moment().format(format);
      setInterval(() => {
        this.date = moment().format(format);
      }, DATE_UPDATE);
    },
    createRecordingChannel() {
      if (this.recordingChannel) return;
      this.recordingChannel = new BroadcastChannel("recordingChannel");
      this.recordingChannel.onmessage = ({ data }) => {
        switch (data.type) {
          case RECORD_MESSAGES.CLOSE_WINDOW: {
            if (data.message.roomId === this.room) {
              window.close();
            }
            break;
          }
          default:
            break;
        }
      };
    },
    closeRecordingChannel() {
      if (this.recordingChannel) {
        this.recordingChannel.close();
        this.recordingChannel = null;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.event-recording {
  &__container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr minmax(580px, 1fr);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &__loader {
    position: fixed;
    top: 50vh;
    transform: translateY(-50%);
  }

  &__player-wrapper {
    position: relative;
    max-width: 100%;
  }

  &__presenter {
    max-width: 100%;
  }

  &__player {
    max-height: 100%;

    ::v-deep .video-js {
      width: 100%;
    }
  }
}
</style>
