<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { auth, socket, socketEvent } from "@/store/modules/store.namespaces";
import socketEventModule from "@/store/modules/socket/modules/event/event";
import { RESET_STATE } from "@/store/modules/socket/action-types";

import BaseWebSocketContainer from "@/components/common/web-socket/BaseWebSocketContainer";
import { SET_SOCKET_CONNECTED } from "@/store/modules/socket/mutation-types";

export default {
  name: "EventWebSocketContainer",
  extends: BaseWebSocketContainer,
  computed: {
    ...mapState(auth, ["user"]),
    socketProps() {
      return {
        ...this.defaultSocketProps,
        user: this.user,
      };
    },
    storeModuleNamespace() {
      return socketEvent;
    },
  },
  methods: {
    ...mapActions(socketEvent, {
      resetState: RESET_STATE,
    }),

    /**
     * Мутация изменения состояния подключения к сокету
     */
    ...mapMutations(socket, {
      setSocketConnected: SET_SOCKET_CONNECTED,
    }),

    chooseNamespace(type) {
      if (socketEventModule.actions[type]) {
        return this.storeModuleNamespace;
      }
      return socket;
    },
    openHandler(reconnect) {
      this.setSocketConnected(true);
      !reconnect && this.resetState(true);
    },
    closeHandler() {
      this.setSocketConnected(false);
    },
  },
};
</script>
