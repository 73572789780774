<template>
  <div class="recording-panel">
    <div class="recording-panel__player">
      <slot />
    </div>
    <template v-if="!onlyPlayer">
      <div class="recording-panel__header">
        <div>
          <span class="header__on-air">{{ $t("broadcast.participantsOnAir") }} </span>
          <span class="header__speaker">{{ speakerName }}</span>
        </div>
        <div>{{ date }}</div>
      </div>
      <div class="recording-panel__chat">
        <div class="recording-panel__sub-title">{{ $t("common.chat") }}</div>
        <div class="recording-panel__section-container">
          <messages class="recording-panel__section" is-screen />
        </div>
      </div>
      <div class="recording-panel__questions">
        <div class="recording-panel__sub-title">{{ $t("common.questions") }}</div>
        <div class="recording-panel__section-container">
          <container-questions class="recording-panel__section" record />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Messages from "@/components/chat/views/Messages";
import ContainerQuestions from "@/containers/common/ContainerQuestions";

export default {
  name: "SpeakerRecordingPanel",
  components: {
    ContainerQuestions,
    Messages,
  },
  props: {
    onlyPlayer: {
      type: Boolean,
      default: false,
    },
    speakerName: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars";

.recording-panel {
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;

  &__header {
    display: flex;
    grid-area: ~"1 / 1 / 2 / 3";
    justify-content: space-between;
    padding: 20px;
    color: #acacac;
  }

  &__player {
    grid-area: ~"2 / 1 / 3 / 3";
  }

  &__chat {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-area: ~"3 / 1 / 4 / 2";
    border-right: 1px #ebebeb solid;
  }

  &__questions {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-area: ~"3 / 2 / 4 / 3";
  }

  &__sub-title {
    padding: 20px;
    text-transform: uppercase;
    color: #acacac;
    border-bottom: 1px #ebebeb solid;
  }

  &__section-container {
    position: relative;
    overflow: hidden;
  }

  &__section {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.header {
  &__on-air {
    color: #000;

    &::before {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 6px;
      content: "";
      vertical-align: middle;
      background-color: @broadcast-dot-indicator-active;
      border-radius: 50%;
      box-shadow: 0 0 0 2px rgba(@text-pale-gray, 0.2);
    }
  }

  &__speaker {
    color: #F79843;
  }
}
</style>
