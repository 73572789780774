<template>
  <div class="container">
    <div class="poll-title" :class="{ 'poll-title--without-timer': !$scopedSlots.timer }">
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>
    <p v-if="poll.test_questions_prompt" class="center">{{ poll.test_questions_prompt }}</p>
    <div class="scale">
      <div class="gradient-scale">
        <transition name="fade">
          <a
            v-show="!isScrolled"
            class="arrow-right presentation-slider__arrow-right icon-arrow-right-circle presentation-slider__control"
            @mousedown="
              $refs.questionWindow.scroll({
                left: (scrolled += 300),
                behavior: 'smooth',
              })
            "
          ></a>
        </transition>
        <div class="sub-questions">
          <div ref="sub" class="sub-question"></div>
          <div
            v-for="question in poll.subQuestions"
            ref="row"
            :title="question.title"
            class="sub-question"
          >
            {{ question.title }}
          </div>
        </div>
        <div ref="questionWindow" class="point" @scroll="isScrolledIntoView">
          <div
            ref="columnRef"
            class="columns"
            :style="
              `grid-template-columns: repeat(${poll.variants.length}, minmax(max-content, min-content))`
            "
          >
            <div
              v-for="item in poll.variants"
              :key="item.id"
              ref="columnItem"
              :title="item.title"
              class="column"
            >
              {{ item.title }}
            </div>
          </div>
          <div ref="container" class="questions">
            <div
              v-for="(question, qIndex) in poll.subQuestions"
              :key="question.id"
              ref="secondRow"
              class="question"
              :style="`grid-template-columns: repeat(${poll.variants.length}, auto);`"
            >
              <ws-radio
                v-for="(i, index) in poll.variants"
                :key="i.id"
                :ref="
                  index == poll.variants.length - 1 && qIndex == poll.subQuestions.length - 1
                    ? 'last'
                    : ''
                "
                :name="'gradient-scale_' + question.id"
                class="radio"
                @input="setVariant(i.id, question.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePoll from "./BasePoll";
import WsRadio from "@/components/base/WsRadio";

export default {
  name: "GradientScale",
  components: {
    WsRadio,
  },
  extends: BasePoll,
  data() {
    return {
      isScrolled: false,
      scrolled: 100,
      minColumnWidth: 60,
    };
  },
  computed: {
    // Картинка вопроса
    pollImg() {
      return this.poll.test_questions_image_url;
    },
    // Размер блока, в котором находится вопрос
    questionBlockSize() {
      return this.$refs.mainContainer?.clientWidth || 640;
    },
  },
  watch: {
    /**
     * Ресайз столбцов и строк при изменении данных
     */
    poll: {
      handler() {
        this.$nextTick(() => {
          this.resizeColumns();
          this.isScrolledIntoView();
        });
      },
      deep: true,
    },
  },
  mounted() {
    // Сразу ресайзим колонки под максимальный контент.
    this.resizeColumns();
    // Проверяем, видел ли крайний элемент. Если он виден, то стрелка скрывается сразу.
    this.isScrolledIntoView();
  },
  methods: {
    /**
     * Метод выбора варианта.
     *
     * @param {number} variantId - ID варианта
     * @param {number} questionId - ID вопроса
     */
    setVariant(variantId, questionId) {
      const answerId = this.answers.findIndex(answer => answer.test_question_id === questionId);
      if (answerId >= 0) {
        this.answers[answerId] = {
          variant_id: variantId, // ид конкретной полосы шкалы
          test_question_id: questionId, // значение для конкретной шкалы - число - порядковый номер точки куда нажал пользователь при выборе
        };
      } else {
        this.answers.push({
          variant_id: variantId, // ид конкретной полосы шкалы
          test_question_id: questionId, // значение для конкретной шкалы - число - порядковый номер точки куда нажал пользователь при выборе
        });
      }
    },
    /**
     * Метод для ресайза колонок и строк.
     * Необходим, т.к. при статичных размерах колонок - может быть много/мало места для тескта.
     */
    resizeColumns() {
      const { secondRow, columnItem, columnRef, sub, row } = this.$refs;

      for (const gridElement of secondRow) {
        let grid = "";
        for (const key in columnItem) {
          const { width } = getComputedStyle(columnItem[key]);
          grid += `${Math.max(parseFloat(width), this.minColumnWidth)}px `;
        }
        gridElement.style.gridTemplateColumns = grid;
      }
      sub.style.height = getComputedStyle(columnRef).height;
      for (const r in row) {
        secondRow[r].style.height = getComputedStyle(row[r]).height;
      }
    },
    /**
     * Метод необходимый для стрелки. Необходим для того, что бы отображать/скрывать стрелку.
     */
    isScrolledIntoView() {
      const rect = this.$refs.last[0].$el.getBoundingClientRect();
      const { right, left } = rect;
      const isVisible =
        right >= 0 && left + this.$refs.questionWindow.clientWidth <= window.innerWidth;
      this.isScrolled = isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
.poll-title {
  position: relative;
  padding-bottom: 25px;
  margin: 15px 15px 25px;
  font-size: 24px;
  //color: #232323;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }

  &--without-timer {
    padding-bottom: 0;
  }
}
.gradient-scale {
  display: grid;
  grid-template-columns: fit-content(300px) auto;
  @media (max-width: 768px) {
    grid-template-columns: 150px auto;
  }
  padding-top: 20px;
  word-break: break-word;
  position: relative;
}

.columns,
.question {
  display: grid;
  text-align: center;
  max-width: 100%;
  word-break: break-word;
  align-items: center;
}

.column,
.sub-question {
  word-break: break-word;
  max-width: 100%;
  min-width: 60px;
  padding-bottom: 20px;
  height: max-content;
}

.question .radio {
  margin: 0 auto;
}

.center {
  text-align: center;
  padding-bottom: 20px;
  word-break: break-word;
}

.container {
  h1 {
    text-align: center;
  }
  position: relative;
}
.arrow-right {
  color: inherit;
  text-decoration: inherit;
  position: absolute;
  right: 0;
  font-size: 30px;
  top: calc(50% - 15px);
}

.column {
  padding-right: 10px;
  min-width: 60px;
  max-width: 300px;
}

.question {
  padding-bottom: 20px;
  padding-right: 10px;
}

.scale {
  display: flex;
  justify-content: center;
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}
</style>
