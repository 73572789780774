<template>
  <v-select
    :class="$style.select"
    :value="value"
    :options="options"
    :disabled="disabled"
    :clearable="false"
    :searchable="false"
    :label="label"
    :placeholder="placeholder"
    :selectable="selectable"
    :get-option-key="getOptionKey"
    transition=""
    :append-to-body="appendToBody"
    :calculate-position="calculatePosition"
    v-on="$listeners"
  >
    <template #selected-option-container="{ option }">
      <slot name="selected-option-container">
        <span v-line-clamp="1" class="vs__selected">{{ option[label] }}</span>
      </slot>
    </template>
    <template #option="option">
      <slot name="option" v-bind="option">
        <div :class="$style.option" class="flex align-center justify-between">
          <span :class="$style.option__text" class="semi-bold">{{ option[label] }}</span>
          <ws-icon :class="$style.option__selected" color="primary" regular md>check</ws-icon>
        </div>
      </slot>
    </template>
    <template #open-indicator="{ attributes }">
      <ws-icon :class="$style.dropdownIcon" regular color="default" v-bind="attributes">
        chevron-down
      </ws-icon>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { createPopper } from "@popperjs/core";
import WsIcon from "@/components/base/WsIcon";

/**
 * UI компонент селекта<br>
 * Принимает опции вида [string|number] или [object]<br>
 * Для объекта приняты правила для свойств текст это свойство label и ключ это свойство id<br>
 * Используется vue-select {@link https://vue-select.org/guide/install.html#yarn-npm}
 *
 * @vue-prop {any} value - выбраное значение селекта связанное двухсторонним байндингом
 * @vue-prop {Array} options - опции для селекта
 * @vue-prop {string} label - имя ключ в объекте опции по которому искать значение для отображения
 * @vue-prop {boolean} disabled - заблокирован ли селект для выбора опций
 * @vue-prop {string} placeholder - текст для вида когда не выбрано ни одно значение
 * @vue-prop {Function} selectable - функция которая возвращает boolean значения и определяет можно ли выбрать конкретную опцию
 * @vue-prop {Function} getOptionKey - функция для привязки опции по ключу
 * @vue-prop {string} fullscreenGroup - группа элементов в режиме фуллскрина к которым привязан селект
 * @vue-prop {Array} offset - смещенние dropdown элемента относительно reference
 * @vue-prop {object} classes - классы для переопределения поведения выпадющего меню
 * @vue-prop {number} minWidth - минимальная ширина dropdown(случаи когда нужно сделать dropdown < 200px)
 * @vue-prop {boolean} appendToBody - позиционирует dropdown в конец body
 * @vue-computed {boolean} inFullscreenMode - определяет находится ли нужная группа в режиме фуллскрина
 *
 */
export default {
  name: "WsSelect",
  components: {
    WsIcon,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    value: {},
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "label",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    getOptionKey: {
      type: Function,
    },
    fullscreenGroup: {
      type: String,
      default: "",
    },
    offset: {
      type: Array,
      default: () => [0, -1],
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
    minWidth: {
      type: Number,
      default: 200,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    inFullscreenMode() {
      return (
        this.fullscreenGroup &&
        this.$fullscreen.isEnabled &&
        this.$fullscreen.group === this.fullscreenGroup
      );
    },
  },
  methods: {
    getClasses() {
      const selectOpen = this.classes.select_open ?? this.$style.select_open;
      const selectDropUp = this.classes.select_dropUp ?? this.$style.select_dropUp;
      const dropdownOpenUp = this.classes.dropdownOpenUp ?? this.$style.dropDownOpenUp;
      const dropdown = this.classes.dropdown ?? this.$style.dropdown;
      return { selectOpen, selectDropUp, dropdownOpenUp, dropdown };
    },
    /**
     * Использует плагином vue-select для расчета позиции dropdown меню
     *
     * @param dropdownList
     * @param component
     * @param width
     * @returns {function(): void}
     */
    calculatePosition(dropdownList, component, { width }) {
      const widthVal = +width.replace("px", "");
      dropdownList.style.width = `${Math.max(widthVal, this.minWidth)}px`;
      const { selectOpen, selectDropUp, dropdownOpenUp, dropdown } = this.getClasses();
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "computeStyles",
            options: {
              adaptive: false,
            },
          },
          {
            name: "offset",
            options: {
              offset: this.offset,
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn: ({ state }) => {
              component.$el.classList.toggle(selectDropUp, state.placement === "top");
              dropdownList.classList.toggle(dropdownOpenUp, state.placement === "top");
            },
          },
          {
            name: "setClasses",
            enabled: true,
            phase: "beforeMain",
            fn: () => {
              if (!component.$el.classList.contains(selectOpen)) {
                component.$el.classList.add(selectOpen);
              }

              if (!dropdownList.classList.contains(dropdown)) {
                dropdownList.classList.add(dropdown);
              }
            },
          },
          {
            name: "toggleFullscreen",
            enabled: true,
            phase: "beforeWrite",
            fn: ({ state }) => {
              if (
                this.inFullscreenMode &&
                state.elements.popper.parentElement !== this.$fullscreen.element
              ) {
                this.$fullscreen.element.append(state.elements.popper);
              }
            },
          },
          {
            name: "preventOverflow",
            options: {
              padding: 5,
            },
          },
        ],
      });
      return () => popper.destroy();
    },
  },
};
</script>

<style lang="scss" module>
.dropdownIcon {
  margin-right: 8px;
}

.option {
  min-height: 36px;
  margin-right: -8px;
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;

  &__selected {
    display: none;
    margin-left: 5px;
  }

  &__text {
    word-break: break-word;
    white-space: initial;
  }
}

:global(.vs__dropdown-option--selected) .option__selected {
  display: initial;
}

.dropdown {
  padding: 0;
  border-top-style: solid;

  &[data-popper-reference-hidden],
  &[data-popper-escaped] {
    display: none;
  }
}

.select :global(.vs__dropdown-toggle) {
  min-height: 38px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.select :global(.vs__selected) {
  padding-left: 18px;
  margin-left: 0;
}

.select :global(.vs__search) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.select :global(.vs__selected-options) {
  align-items: center;
}

.select_open:global(.vs--open) :global(.vs__selected) {
  position: initial;
}

.select_open.select_dropUp :global(.vs__dropdown-toggle) {
  border-top-color: transparent;
  border-bottom-color: $gray;
  border-radius: 0 0 5px 5px;
}

.select:not(:global(.vs--disabled)) :global(.vs__dropdown-toggle) {
  background-color: $white;
}

.select:global(.vs--disabled) :global(.vs__selected) {
  color: $gray;
}

/* stylelint-disable */
.select:hover:not(:global(.vs--disabled)):not(.select_open) :global(.vs__dropdown-toggle) {
  border-color: var(--base-color);
}
/* stylelint-enable */

.dropdownOpenUp {
  border-radius: 5px 5px 0 0;
}
</style>
