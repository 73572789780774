<template>
  <div class="recording-presenter">
    <div class="recording-presenter__header">
      <div class="recording-presenter__name">{{ presentationInfo.name }}</div>
      <div>
        {{ presentationInfo.currentSlide }}
        {{ $t("common.of") }}
        {{ presentationInfo.slidesLength }}
      </div>
    </div>
    <div class="recording-presenter__body">
      <screen-presentation-slider
        class="recording-presenter__slider"
      />
    </div>
    <div class="recording-presenter__preview">
      <presentation-slides />
    </div>
  </div>
</template>

<script>
import ScreenPresentationSlider
  from "@/components/screen/room/Presentation/components/presentation-slider/ScreenPresentationSlider";
import PresentationSlides
  from "@/components/event/room/Presentation/components/presentation-slider/presentation-slides/PresentationSlides";

export default {
  name: "SpeakerBroadcastEventRecordingPresenter",
  components: {
    ScreenPresentationSlider,
    PresentationSlides,
  },
  props: {
    presentationInfo: {
      type: Object,
      default: () => ({
        name: "",
        currentSlide: "",
        slidesLength: "",
      }),
    },
  },
};
</script>

<style lang="less" scoped>
.recording-presenter {
  display: grid;
  grid-template-rows: min-content auto min-content;
  border-right: 1px #ebebeb solid;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: #acacac;
    border-bottom: 1px #ebebeb solid;
  }

  &__name {
    color: #313131;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-bottom: 1px #ebebeb solid;
  }

  &__slider {
    display: table;
    height: 100%;
    max-height: 100%;

    ::v-deep .presentation-youtube {
      width: 100%;
      height: 100%;
    }
  }

  &__preview {
    padding: 12px;
    overflow: hidden;

    ::v-deep .presentation-slides__slide {
      flex-basis: 110px;
      width: 110px;
      height: 80px;
    }
  }
}
</style>
