<template>
  <div
    class="presentation-screen"
    :class="{
      'presentation-screen--test': slide && slide.type === 'test' && !slide.isTestAnswered,
    }"
  >
    <presentation-screen-video-slide
      v-if="isVideoSlide"
      :room="room"
      :has-presentation-video="videoStatus"
      :slide="videoSlide"
      :presentation-video-src="videoSrc"
    />
    <presentation-screen-test-slide
      v-else-if="showTestSlide"
      class="presentation-screen__test-slide"
      :show-score="showTestScore"
      :slide="slide"
      is-screen
    />
    <timer-slide v-else-if="slide && slide.type === 'timer'" :slide="slide" isActive />
    <img v-else v-show="slideSrc" ref="image" class="presentation-image" :src="slideSrc" alt />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// eslint-disable-next-line max-len
import presentationTestSlideMixin from "@/components/event/room/Presentation/components/mixins/presentationTestSlideMixin";
import presentationScreenSlideHelper from "@/components/common/presentation/mixins/presentationScreenSlideHelper.mixin";
import { socket } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import PresentationScreenVideoSlide from "@/components/common/presentation/PresentationScreenVideoSlide";
import PresentationScreenTestSlide from "@/components/common/presentation/PresentationScreenTestSlide";
import TimerSlide from "@/components/common/presentation/components/TimerSlide";

export default {
  name: "ScreenPresentationScreen",
  components: {
    PresentationScreenTestSlide,
    PresentationScreenVideoSlide,
    TimerSlide,
  },
  mixins: [presentationTestSlideMixin, presentationScreenSlideHelper],
  mounted() {
    let tick = {};
    window.addEventListener(
      "keydown",
      function(e) {
        if (e.keyCode === 116) {
          e.preventDefault();
          tick = {
            obj: {
              play: true,
            },
            type: "youtubeSlideStartStop",
          };
          this.setSocketObj(tick);
        }
        if (e.keyCode === 190 || e.keyCode === 27) {
          tick = {
            obj: {
              play: false,
            },
            type: "youtubeSlideStartStop",
          };
          this.setSocketObj(tick);
        }
      }.bind(this),
    );
  },
  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
  },
};
</script>

<style scoped lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.presentation-youtube {
  width: 95%;
}

.presentation-image {
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: auto;
  width: 100%;
  height: 100%;
  margin: auto;
  vertical-align: middle;
}

.presentation-screen {
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ~"calc(100% - 20px)";
  height: 100%;
  margin: 8px auto;
  overflow: auto;
  background-color: #fff;
  border-radius: 3px;

  &--test {
    justify-content: normal;
    width: 100%;
    margin: 0;
  }

  ::v-deep .polls-result {
    overflow: auto;
  }

  &__test-slide {
    display: flex;
    flex: 1 1 auto;

    ::v-deep .poll-scores__wrap {
      flex: 1 1 auto;
      height: 100vh;
    }
  }
}
</style>
