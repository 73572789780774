<template>
  <base-relation ref="relationPoll" v-bind="$attrs" class="relation-poll" answer-key="id">
    <template #timer>
      <slot name="timer" />
    </template>
  </base-relation>
</template>

<script>
import BaseRelation from "./BaseRelation";

export default {
  name: "RelationPoll",
  components: {
    BaseRelation,
  },
  inheritAttrs: false,
  methods: {
    /**
     * Отправка ответа на вопрос.
     *
     * @param {string} action - тип ответа на вопрос.
     * @param {boolean} isFromSlide - Из слайда ли данный ответ
     * @param {number} slideId - ID слайда, на котором пользователь ответил.
     */
    async sendAnswer(action, isFromSlide, slideId) {
      await this.$refs.relationPoll.sendAnswer(action, isFromSlide, slideId);
    },
    /**
     * Метод очистки ответов. Вызывается из главного компонента BasePoll.
     */
    clearAnswers() {
      this.$refs.relationPoll.clearAnswers();
    },
    /**
     * Метод пропуска вопроса. Вызывается из главного компонента BasePoll.
     */
    async skipQuestion() {
      await this.$refs.relationPoll.skipQuestion();
    },
  },
};
</script>

<style scoped lang="less">
/* stylelint-disable selector-max-specificity */

.relation-poll {
  ::v-deep .variants-pairs .variants-pair .variant-title {
    position: relative;
    z-index: 1;
  }

  ::v-deep .question-block {
    padding: 12px 8px 17px 35px;
    border-color: #d3d3d3;
  }

  @media (max-width: 400px) {
    ::v-deep .question-block {
      padding: 12px 8px 17px 35px;
    }
  }
}
</style>
