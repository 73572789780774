<template>
  <div :key="key" class="container">
    <div
      class="poll-title uk-position-relative text-center"
      :class="{ 'poll-title--without-timer': !$scopedSlots.timer }"
    >
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>

    <div class="analog-scale text-center">
      <div v-if="poll.test_questions_prompt" class="prompt uk-text-center">
        {{ poll.test_questions_prompt }}
      </div>
      <div v-if="!scaleProps.is_rating" class="analog_poll">
        <div
          v-for="terms in poll.variants"
          :key="terms.id"
          class="term uk-flex uk-flex-center uk-flex-middle"
        >
          {{ terms.title }}
          <div class="analog-scale-radio desktop">
            <ws-radio
              v-for="i in +scaleProps.analog_scale_step"
              :key="i"
              :value="true"
              :currentValue="terms.selected === i"
              :name="'analog-scale_' + terms.id"
              class="radio"
              @input="setVariant(i, terms.id)"
            />
          </div>
          <div class="analog-scale-radio mobile">
            <rating-slider
              class="result-slider"
              icon="like"
              :max="+scaleProps.analog_scale_step"
              @change="setVariant($event, poll.variants[0].id)"
            ></rating-slider>
          </div>
          {{ terms.description }}
        </div>
      </div>
      <div v-else class="rating-poll">
        <!-- TODO: срочные правки после релиза. Очень странно расчитываются проценты. -->
        <rating-slider
          class="result-slider"
          custom-width="50%"
          :marks="+scaleProps.analog_scale_step <= 20 ? true : val => val % (val * 0.05) === 0"
          :max="+scaleProps.analog_scale_step"
          @change="setVariant($event, poll.variants[0].id)"
        ></rating-slider>
      </div>
    </div>
  </div>
</template>

<script>
import BasePoll from "./BasePoll";
import WsRadio from "@/components/base/WsRadio";
import RatingSlider from "@/components/common/elements/RatingSlider";

export default {
  name: "AnalogScale",
  components: {
    WsRadio,
    RatingSlider,
  },
  extends: BasePoll,
  data() {
    return {
      // Выбранный ответ
      selected: 1,
      // Ключ вопроса. Необходим для перерендера.
      key: 0,
    };
  },
  computed: {
    /**
     * Дополнительные настройки вопроса
     *
     * @returns {object} - настройки вопроса
     */
    scaleProps() {
      return JSON.parse(this.poll.props);
    },
    /**
     * Картинка вопроса
     *
     * @returns {string} - URL картинки
     */
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },
  watch: {
    /**
     * Наблюдатель за изменением теста.
     * Необходим для перерендера элементов.
     */
    poll() {
      this.key += 1;
    },
  },
  mounted() {
    // Инициализация компонента. По дефолту стоит единица.
    this.setVariant(this.selected, this.poll.variants[0].id);
  },
  methods: {
    /**
     * Метод выбора варианта.
     *
     * @param {number} i - выбранный поинт шкалы.
     * @param {number} id - id теста.
     */
    setVariant(i, id) {
      const selectedVariant = this.poll.variants.find(item => item.id === id);
      selectedVariant.selected = i;
      const answerId = this.answers.findIndex(answer => answer.variant_id === id);
      const answer = {
        variant_id: id, // id конкретной полосы шкалы
        answer: i, // значение для конкретной шкалы - число - порядковый номер точки куда нажал пользователь при выборе
      };
      if (answerId >= 0) this.answers[answerId] = answer;
      else this.answers.push(answer);
    },
  },
};
</script>

<style lang="scss" scoped>
.poll-title {
  padding-bottom: 25px;
  margin: 15px 15px 25px;
  font-size: 24px;
  //color: #232323;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }

  &--without-timer {
    padding-bottom: 0;
  }
}
.analog-scale-radio {
  display: inline-block;
  padding: 10px;
}

.radio {
  margin-right: 10px;
}

.radio:last-child {
  margin-right: 0;
}

.poll-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.term {
  padding-top: 10px;
  overflow-wrap: break-word;
  word-break: break-all;
  span {
    max-width: 500px;
  }
}
.prompt {
  @media (max-width: 1280px) {
    padding-top: 10px;
  }
  word-break: break-word;
  padding-bottom: 5px;
}

.mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
    padding: 35px;
  }
}

.desktop {
  display: inline-flex;
  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    label {
      --ws-toggle-size: 15px !important;
    }
  }
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}

.rating-poll {
  min-height: 50px;
}
</style>
