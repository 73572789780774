<template>
  <div>
    <div class="poll-title">
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>
    <div v-if="poll.test_questions_prompt" class="text_prompt">
      {{ poll.test_questions_prompt }}
    </div>
    <div class="variant-img-container">
      <variant-img-btn
        v-for="variant in poll.variants"
        :key="variant.id"
        class="variant-img-btn"
        :disabled="isDisabled(variant.id)"
        :variant-id="variant.id"
        :active="isActive(variant.id)"
        click-method="set-variant"
        @set-variant="setVariant"
      >
        <img class="variant-img-btn__img" :src="variant.image" alt="variant" />
      </variant-img-btn>
    </div>
  </div>
</template>

<script>
import VariantImgBtn from "../common/VariantImgBtn";
import BasePoll from "./BasePoll";

export default {
  components: {
    VariantImgBtn,
  },
  extends: BasePoll,
  data() {
    return {
      imgError: false,
    };
  },
  computed: {
    errorMessage() {
      return this.$emit("error", this.$t("testNGames.youHaveToChooseAnswer"));
    },
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },
  methods: {
    setVariant(id) {
      if (+this.poll.max_answers_number === 1) {
        this.answers = [id];
      } else {
        this.isActive(id)
          ? this.answers.splice(this.answers.indexOf(id), 1)
          : this.answers.push(id);
      }
    },
    isActive(id) {
      return this.answers.indexOf(id) > -1;
    },
    isDisabled(id) {
      return (
        +this.poll.max_answers_number === this.answers.length &&
        !this.isActive(id) &&
        this.poll.max_answers_number > 1
      );
    },
  },
};
</script>

<style scoped lang="scss">
.variant-img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 640px;
  margin: 3px auto 0;
}

.poll-title {
  position: relative;
  padding-bottom: 25px;
  margin: 15px 15px 25px;
  font-size: 24px;
  //color: #232323;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }
}

.king-poll {
  margin: auto;
}

.variant-img-btn {
  margin: 0 5px 10px 0;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.variant-img-btn:last-child {
  margin-right: 0;
}
.text_prompt {
  position: relative;
  padding-bottom: 5px;
  margin: 15px 15px 25px;
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: break-spaces;
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}
</style>
