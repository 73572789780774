<template>
  <div v-on-clickaway="unselect" class="relation-wrapper">
    <div class="poll-title">
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer" />
    </div>
    <div v-if="poll.test_questions_prompt" class="text_prompt">
      {{ poll.test_questions_prompt }}
    </div>
    <div class="variants-pairs">
      <div
        v-for="(variant, titleId) in pairs"
        :key="titleId"
        class="variants-pair"
        :class="{ paired: variant.paired }"
      >
        <div
          class="variant-title question-block uk-flex"
          :class="{ selected: titleId === selectedTitleId }"
          @click="selectVariantTitleId(titleId, variant.paired, variant.variant)"
        >
          <div class="marker">{{ titles[titleId].index + 1 }}</div>
          <slot name="titleContent" v-bind="{ titleItem: titles[titleId] }">
            <!--
              TODO: Это временное решение, потом необходимо сделать
                    нормальный компонент.
            -->
            <img v-if="titles[titleId].image" :src="titles[titleId].image" alt="" />
            <template v-else>{{ titles[titleId].title }}</template>
          </slot>
          <div class="connection-line"></div>
        </div>
        <div
          class="variant-description question-block uk-flex"
          :class="{ selected: variant.variant === selectedDescriptionId }"
          @click="selectDescriptionId(variant.variant, titleId)"
        >
          <div class="marker">{{ variants[variant.variant].marker }}</div>
          <slot name="variantContent" v-bind="{ variantItem: variants[variant.variant] }">
            {{ variants[variant.variant].description }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import shuffle from "lodash/shuffle";
import BasePoll from "./BasePoll";
import createAlphabeticIndex from "@/utils/createAlphabeticIndex";

export default {
  name: "BaseRelation",
  extends: BasePoll,
  mixins: [clickaway],
  props: {
    answerKey: {
      type: String,
      default: "description",
    },
  },
  data() {
    return {
      answers: [],
      selectedTitleId: null,
      selectedDescriptionId: null,
      pairs: {},
      variants: [],
      titles: {},
    };
  },
  computed: {
    descriptions() {
      return this.poll.variants.descriptions || [];
    },
    variantTitles() {
      return this.poll.variants.titles || [];
    },
    hasError() {
      const answers = this.getAnswers();

      return answers.length < 1;
    },
    errorMessage() {
      return this.$t("testNGames.connectFieldsAtRightAndTheLeft");
    },
    sendData() {
      return this.getAnswers();
    },
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },

  watch: {
    descriptions() {
      this.prepareData();
    },
  },

  created() {
    this.prepareData();
  },

  methods: {
    selectVariantTitleId(id) {
      if (this.selectedTitleId === id) {
        this.selectedTitleId = null;
      } else {
        if (this.pairs[id].paired) {
          this.pairs[id].paired = false;
        }
        this.selectedTitleId = id;
        if (this.selectedDescriptionId || this.selectedDescriptionId === 0) {
          this.pairVariantWithTitle();
        }
      }
    },
    selectDescriptionId(id, pairId) {
      if (this.selectedDescriptionId === id) {
        this.selectedDescriptionId = null;
      } else {
        if (this.pairs[pairId].paired) {
          this.pairs[pairId].paired = false;
        }
        this.selectedDescriptionId = id;
        if (this.selectedTitleId) {
          this.pairVariantWithTitle();
        }
      }
    },
    /**
     * Метод пропуска вопроса
     *
     * @returns {void}
     */
    async skipQuestion() {
      this.sendPollAnswer({
        type: this.moduleType,
        // Если это повторный вопрос - всегда отправлять is_last_question: true
        is_last_question: this.isLastQuestion || (this.poll?.isRepeated && this.poll?.timerDelta),
        user_test_answer_time: this.time,
        poll_id: this.poll?.id,
        test_id: this.pollChain?.id,
        variants: this.sendData,
        skipQuestion: true,
        moduleType: this.moduleType,
      });
    },
    unselect() {
      this.selectedTitleId = null;
      this.selectedDescriptionId = null;
    },
    pairVariantWithTitle() {
      let prevVariantPosition = null;
      const current = this.pairs[this.selectedTitleId].variant;
      for (const key in this.pairs) {
        if (this.pairs.hasOwnProperty(key)) {
          if (this.pairs[key].variant === this.selectedDescriptionId) {
            prevVariantPosition = key;
          }
        }
      }
      // this.animatedMoveByPairsArray(prevVariantPosition, this.selectedTitleId);
      if (this.selectedTitleId !== prevVariantPosition) {
        this.pairs[this.selectedTitleId].variant = this.selectedDescriptionId;
        this.pairs[prevVariantPosition].variant = current;
        this.pairs[prevVariantPosition].paired = false;
      }
      this.pairs[this.selectedTitleId].paired = true;
      this.selectedTitleId = null;
      this.selectedDescriptionId = null;
    },
    animatedMoveByPairsArray(startPairId, targetPairId) {
      const startIndex = this.titles[startPairId].index;
      const targetIndex = this.titles[targetPairId].index;
      const keys = Object.keys(this.titles);
      const distance = targetIndex - startIndex;
      if (distance === 0) {
        this.pairs[targetPairId].paired = true;
        this.selectedTitleId = null;
        this.selectedDescriptionId = null;
      } else {
        const nextId = distance > 0 ? keys[startIndex + 1] : keys[startIndex - 1];
        const movedValue = this.pairs[keys[startIndex]].variant;
        const currentValue = this.pairs[nextId].variant;
        this.pairs[nextId].variant = movedValue;
        this.pairs[keys[startIndex]].variant = currentValue;
        setTimeout(() => {
          this.animatedMoveByPairsArray(nextId, targetPairId);
        }, 200);
      }
    },
    clearAnswers() {
      this.answers = [];
      this.selectedTitleId = null;
      this.selectedDescriptionId = null;
      this.pairs = {};
      this.variants = [];
      this.titles = {};
    },
    getAnswers() {
      const answers = [];
      for (const key in this.pairs) {
        if (this.pairs.hasOwnProperty(key)) {
          if (this.pairs[key].paired) {
            answers.push({
              variant_id: key,
              answer: this.variants[this.pairs[key].variant][this.answerKey],
            });
          }
        }
      }

      return answers;
    },
    checkErrors() {
      const answers = this.getAnswers();

      return answers.length < 1;
    },

    /**
     * Метод подготовки данных для показа.
     */
    prepareData() {
      const initVariants = shuffle(this.descriptions);
      const tmpPairs = {};
      const tmpTitles = {};

      for (let i = 0, qCount = this.variantTitles.length; i < qCount; i++) {
        const { id } = this.variantTitles[i];

        tmpTitles[id] = this.variantTitles[i];
        tmpTitles[id].marker = i;
        tmpTitles[id].index = i;

        initVariants[i].marker = createAlphabeticIndex(i);
        // initVariants[i].id = i;

        tmpPairs[id] = { variant: i, connected: false };
      }

      this.variants = initVariants;
      this.titles = tmpTitles;
      this.pairs = tmpPairs;
    },
  },
};
</script>

<style scoped lang="scss">
.relation-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  font-family: ProximaNova;

  .poll-title {
    position: relative;
    margin: 15px 15px 25px 15px;
    padding-bottom: 25px;
    //color: #232323;
    font-size: 24px;
    text-align: center;
    word-break: break-word;

    @media (max-width: 1280px) {
      margin: 7.5px 7.5px 12.5px;
    }
  }
  .variants-pairs {
    display: flex;
    flex-direction: column;
    .variants-pair {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      word-break: break-word;
      text-align: center;

      .variant-title {
        position: relative;
        z-index: 1;
        max-width: 50%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .variant-description {
        margin-left: 25px;
        flex-grow: 1;
        position: relative;
        z-index: 1;
        max-width: 50%;
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .connection-line {
        height: 1px;
        width: 0;
        opacity: 0;
        background-color: var(--base-color);
        position: absolute;
        top: calc(50% - 1px);
        left: 100%;
        transition: width 0.6s;
      }
      &.paired {
        .question-block {
          background-color: var(--base-color);
          border: solid 1px var(--base-color);
          img {
            max-width: 100%;
            vertical-align: middle;
          }
          .marker {
            color: #fff;
            border-color: #fff;
          }
        }
        .connection-line {
          width: 30px;
          opacity: 1;
        }
      }
    }
  }
}
.question-block {
  padding: 12px 12px 12px 35px;
  border: solid 1px var(--base-color);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 15px;
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  &:hover {
    box-shadow: 0 4px 10px 0 var(--base-shadow-color);
  }
  &.selected {
    box-shadow: 0 4px 10px 0 var(--base-shadow-color);
    background-color: var(--base-bg-color);
    border: solid 1px var(--base-color);
  }
  .title-image {
    max-width: 100%;
    width: auto;
    height: 200px;
    min-height: 190px;
    background: no-repeat top left;
    background-size: contain;
  }
  .marker {
    position: absolute;
    text-align: center;
    line-height: 18px;
    font-size: 15px;
    top: 11px;
    left: 8px;
    padding: 0 2px;
    min-width: 21px;
    height: 21px;
    border-radius: 11px;
    border: solid 1px var(--base-color);
    color: var(--base-color);
  }
}
@media (max-width: 400px) {
  .relation-wrapper {
    .variants-pairs {
      .variants-pair {
        width: 100%;
        .variant-title {
          min-width: 50%;
        }
      }
    }
  }
  .question-block {
    padding: 8px 8px 8px 35px;
  }
}
.text_prompt {
  position: relative;
  padding-bottom: 5px;
  margin: 15px 15px 25px;
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: break-spaces;
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}
</style>
