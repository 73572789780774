<template>
  <div class="word-cloud-input">
    <input
      v-model.trim="answer"
      type="text"
      :placeholder="placeholder"
      :maxlength="maxLength"
      @input="inputHandler"
    />
    <span
      class="counter"
      :class="{
        'counter--red': counter === 0,
      }"
    >
      {{ counter }}
    </span>
    <small v-if="answer.length >= maxLength" class="max-length-error">
      {{ `${$t("Tests.maxLength")}  ${maxLength}` }}
    </small>
  </div>
</template>

<script>
export default {
  name: "WordCloudInput",
  model: {
    event: "model-input",
  },
  props: {
    // Максимальная длина ответа
    maxAnswerLength: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      // Ответ
      answer: "",
      // Количество символов в поле ввода
      defaultAnswerLength: 60,
    };
  },
  computed: {
    // Плейсхолдер поля ввода
    placeholder() {
      return this.$t("testNGames.wordCloudPlaceholder");
    },
    // Максимальная длина поля ввода
    maxLength() {
      return this.maxAnswerLength || this.defaultAnswerLength;
    },
    // Счетчик символов внизу поля ввода
    counter() {
      return this.maxLength - this.answer.length;
    },
  },
  methods: {
    /**
     * Метод отправки данных в родительский компонент вопроса типа "Облако слов"
     *
     * @param {object} event - событие ввода в поле
     */
    inputHandler(event) {
      const { value } = event.target;
      value?.length <= this.maxLength && this.$emit("model-input", value);
    },
  },
};
</script>

<style scoped lang="less">
.word-cloud-input {
  position: relative;
  width: 280px;
  margin: 0 auto 10px;
  text-align: center;

  & > input {
    display: block;
    width: 100%;
    padding: 5px 30px 5px 15px;
    line-height: 20px;
    border: 1px solid #979797;
    border-radius: 5px;
  }

  .counter {
    position: absolute;
    top: 30%;
    right: 10px;
    font-size: 12px;
    color: #131313;
    opacity: 0.4;
    transform: translateY(-50%);

    &--red {
      color: #ff0000;
      opacity: 1;
    }
  }

  .max-length-error {
    color: red;
  }
}
</style>
