<template>
  <base-relation ref="relationPoll" v-bind="$attrs" class="relation-image" answer-key="id">
    <template #timer>
      <slot name="timer" />
    </template>
    <template #titleContent="{ titleItem }">
      <img :src="titleItem.image" alt="" />
    </template>
    <template #variantContent="{ variantItem }">
      {{ variantItem.title }}
    </template>
  </base-relation>
</template>

<script>
import BaseRelation from "./BaseRelation";

export default {
  name: "RelationImagesPoll",
  components: {
    BaseRelation,
  },
  inheritAttrs: false,
  methods: {
    async sendAnswer(action, isFromSlide, slideId) {
      await this.$refs.relationPoll.sendAnswer(action, isFromSlide, slideId);
    },
    clearAnswers() {
      this.$refs.relationPoll.clearAnswers();
    },
  },
};
</script>
