<template>
  <base-variant-button v-bind="{ active, disabled }">
    <template #default="{ customStyle }">
      <div
        :style="customStyle"
        class="variant-img-btn"
        :class="{ 'variant-img-btn--active': active }"
        @click="onBtnClick"
      >
        <slot />
        <div :class="{ 'variant-checked--show': active }" class="variant-checked"></div>
      </div>
    </template>
  </base-variant-button>
</template>
<script>
import BaseVariantButton from "./BaseVariantButton";

export default {
  components: {
    BaseVariantButton,
  },
  props: ["active", "action", "disabled", "clickMethod", "variant-id"],
  methods: {
    onBtnClick() {
      if (this.disabled) {
        return;
      }
      this.$emit(this.clickMethod, this.variantId);
    },
  },
};
</script>

<style scoped lang="less">
@import url(~@/styles/_mixin.less);

.variant-checked {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #11c31f;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
  transition: opacity ease 0.3s;
  opacity: 0;
  &--show {
    opacity: 1;
  }
  &:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 1px;
    display: block;
    width: 8px;
    height: 13px;
    border: solid 3px #ffffff;
    border-top: none;
    border-left: none;
    transform: rotate(48deg) skew(20deg);
    border-radius: 2px;
  }
}
.variant-img-btn {
  box-shadow: 0 5px 11px 0 rgba(185, 185, 185, 0.5);
  display: block;
  color: #383838;
  transition: all ease 0.3s;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  height: 124px;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: all ease 0.3s;
  opacity: 0.7;

  @media (min-width: 768px) {
    width: 148px;
    height: 148px;
  }

  &:hover {
    opacity: 0.7;
  }
  &--active {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    .object-fit();
  }
}
</style>
