<template>
  <div class="presentation-slider">
    <presentation-screen
      :video-status="hasVideoSrc"
      :slide-src="slideSrc"
      :video-src="videoSrc"
      :video-slide="videoSlide"
      :slide="currentSlide"
    />
  </div>
</template>
<script>
import presentationSliderPropsMixin from "@/components/common/presentation/mixins/presentationSliderProps.mixin";
import PresentationScreen from "./presentation-screen/ScreenPresentationScreen";

export default {
  name: "ScreenPresentationSlider",
  components: {
    PresentationScreen,
  },
  mixins: [presentationSliderPropsMixin],
};
</script>

<style scoped lang="less">
.presentation-slider {
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
</style>
