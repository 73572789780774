<template>
  <div>
    <div class="poll-title">{{ poll.title }} <slot name="timer"></slot></div>
    <rating-slider
      class="poll-slider"
      :max="poll.variants.length"
      :marks="marks"
      @change="setVariant"
    ></rating-slider>
  </div>
</template>

<script>
import RatingSlider from "@/components/common/elements/RatingSlider";
import BasePoll from "./BasePoll";

export default {
  name: "RatingPoll",
  components: {
    RatingSlider,
  },
  extends: BasePoll,
  data() {
    return {
      value: 1,
    };
  },
  computed: {
    marks() {
      return this.poll.variants.map(variant => {
        return variant.title;
      });
    },
    hasError() {
      return this.answers.length < 1;
    },
  },
  methods: {
    setVariant(val) {
      const variant = this.poll.variants.find(item => {
        return item.title === val.toString();
      });

      this.answers = [];
      this.answers.push(variant.id);
    },
    clearAnswers() {
      this.answers = [];
    },
  },
};
</script>

<style scoped lang="less">
.poll-title {
  margin-top: 15px;
  margin-bottom: 90px;
  font-size: 24px;
  line-height: normal;
  //color: #000000;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }
}

.poll-slider {
  margin: 0 auto 60px;
}
</style>
